<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询
        </vxe-button>
        <vxe-button v-permission="'AddWorkPerson'" status="my-orange" icon="fa fa-plus" @click="handleAdd">添加
        </vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent"
                @reset="searchReset">
        <vxe-form-item title="职业" span="8">
          <vxe-select v-model="searchData.sceneCode" placeholder="请选择服务项目" clearable>
            <vxe-option
              key=""
              label="请选择服务项目"
              value=""
            />
            <vxe-option
              v-for="(item, index) in codeList"
              :key="index"
              :label="item.name"
              :value="item.sceneCode"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <!--        <template v-slot:jiankong="{ row }">-->
        <!--          <img :src="row.pictures" alt="">-->
        <!--        </template>-->
        <template v-slot:operation="{ row }">
          <vxe-button type="text" status="primary" @click="handleEdit(row.id)">修改
          </vxe-button>
          <vxe-button type="text" status="primary"
                      @click="handleDelete(row.id)">
            删除
          </vxe-button>
        </template>
      </vxe-grid>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        ></vxe-pager>
      </div>
    </div>
  </div>
</template>

<script>
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MiniappMenu',
  components: { ...CustomTemplate },
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tableColumn: [
        {
          title: '描述',
          field: 'desc'
        },
        {
          title: '类型',
          field: 'type'
        },
        {
          title: '操作',
          fixed: 'right',
          slots: {
            default: 'operation'
          },
        }
      ],
      tableData: [],
      codeList: [{ name: '首页轮播图', sceneCode: 'homePage' }, { name: '活动中心轮播图', sceneCode: 'activityPage' }]
    }
  },
  computed: {
    ...mapState(['layouts'])
  },
  created() {
    this.getLists()
  },
  methods: {
    ...mapActions(['swiperList', 'swiperAdd', 'swiperUpdate', 'swiperDetail', 'swiperDelete']),
    customListGetList(resetCurrentPage = false) {
      this.tablePage.currentPage = 1
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    handleAdd() {
      this.$router.push({
        path: '/config/config/swiperImageAdd/add',
        params: {
          type: 'add',
          id: ''
        }
      })
    },
    handleEdit(id) {
      this.$router.push({
        path: '/config/config/swiperImageAdd/edit/' + id,
        params: {
          type: 'edit',
          id: id
        }
      })
    },
    getLists() {
      this.loading = true
      this.swiperList({
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        param: {
          sceneCode: this.searchData.sceneCode
        }
      }).then((res) => {
        if (res.code == 200) {
          res.data.dataList.forEach((item) => {
            this.codeList.forEach((code) => {
              if (item.sceneCode === code.sceneCode) {
                item.type = code.name
              }
            })
          })
          this.tableData = res.data.dataList
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    handleDelete(id) {
      this.$confirm('确认删除吗？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.swiperDelete({ id }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功')
          this.getLists()
        }).catch(() => {
        })
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  overflow: auto;
}
</style>
